import { ParsedUrlQuery } from "querystring";
import {
  groupBy,
  keys,
  prop,
  propEq,
  find,
  map,
  pipe,
  sort,
  chain,
  uniq,
  propOr,
  pathEq,
  filter,
  pathOr,
  path,
} from "ramda";
import { FaqAccordionItem, FaqSubject } from "@/services/prepr/queries/getFaqSubjects";
import {
  ServiceAccordionItem,
  ServiceSubCategory,
} from "@/services/prepr/queries/getServiceSubcategories";
import { extractQueryParam } from "@/utilities/extractQueryParam";
import { isTruthy } from "./ramdaExt";

type Subcategory = ServiceSubCategory | FaqSubject;
type AccordionItem = FaqAccordionItem | ServiceAccordionItem;

const flattenSubjects = (subjects: Subcategory[]) => ({
  accordion_items: uniq(
    chain<Subcategory, AccordionItem>(propOr(null, "accordion_items"))(subjects)
  ),
});

export const getSubcategoryFilters = (
  categories: Record<string, Subcategory[]>,
  selectedCategory?: string
) => {
  return (
    selectedCategory &&
    pipe(
      map(prop("name")),
      sort<string>((a, b) => a.localeCompare(b))
    )(categories[selectedCategory])
  );
};

export const getFilteredItems = (
  subjects: Subcategory[],
  categories: Record<string, Subcategory[]>,
  selectedCategory?: string,
  selectedSubCategory?: string
) => {
  const filteredSubject =
    selectedCategory && selectedSubCategory
      ? find(propEq<string>("name", selectedSubCategory), categories[selectedCategory])
      : selectedCategory
      ? flattenSubjects(filter(pathEq(["category", 0, "name"], selectedCategory), subjects))
      : { accordion_items: [] };
  return filteredSubject?.accordion_items;
};

export const groupByCategoryName = pipe(
  filter<Subcategory>(pipe(path(["category", 0, "name"]), isTruthy)),
  groupBy<Subcategory>(pathOr("", ["category", 0, "name"]))
);

export const filterFaqOrServiceItems = <T extends AccordionItem[]>(
  subCategories: Subcategory[],
  query: ParsedUrlQuery
) => {
  const selectedCategory = extractQueryParam(query.category);
  const selectedSubCategory = extractQueryParam(query.subCategory);
  const categories = groupByCategoryName(subCategories);
  const subCategoryFilters = getSubcategoryFilters(categories, selectedCategory);
  const accordionItems = getFilteredItems(
    subCategories,
    categories,
    selectedCategory,
    selectedSubCategory
  );

  return {
    accordionItems: accordionItems as T,
    selectedCategory,
    categoryFilters: keys(categories),
    selectedSubCategory,
    subCategoryFilters,
  };
};

import React from "react";
import FilterPill from "./FilterPills/FilterPill";

interface FilterPillsProps {
  heading?: string;
  items: string[] | { key: string; value: string }[];
  selectedItem?: string;
  defaultItem?: string;
  onClick: (item: string | undefined) => void;
}

const FilterPills: React.FC<FilterPillsProps> = ({
  heading,
  items,
  selectedItem,
  defaultItem,
  onClick,
}) => {
  return (
    <>
      {heading && (
        <h3 className="text-h4-sm tablet:text-h3-sm font-sans font-bold tracking-wider">
          {heading}
        </h3>
      )}
      <ul className="tablet:mt-6 mt-4 flex flex-row flex-wrap gap-4">
        {defaultItem && (
          <li key="default">
            <FilterPill onClick={() => onClick(undefined)} isActive={selectedItem === undefined}>
              {defaultItem}
            </FilterPill>
          </li>
        )}
        {items.map((item, i) => {
          if (typeof item !== "string") {
            return (
              <li key={`${item}-${i}`}>
                <FilterPill onClick={() => onClick(item.key)} isActive={selectedItem === item.key}>
                  {item.value}
                </FilterPill>
              </li>
            );
          }
          return (
            <li key={`${item}-${i}`}>
              <FilterPill onClick={() => onClick(item)} isActive={selectedItem === item}>
                {item}
              </FilterPill>
            </li>
          );
        })}
      </ul>
    </>
  );
};

export default FilterPills;

import React from "react";

type ChevronDownProps = React.SVGProps<SVGSVGElement>;

const ChevronDown: React.FC<ChevronDownProps> = (props) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M28.793 9.36943L27.6306 8.20696C27.3546 7.93101 26.9084 7.93101 26.6325 8.20696L16 18.816L5.36751 8.20696C5.09157 7.93101 4.64537 7.93101 4.36943 8.20696L3.20695 9.36943C2.93102 9.64537 2.93102 10.0916 3.20695 10.3675L15.501 22.6615C15.7769 22.9375 16.2231 22.9375 16.499 22.6615L28.793 10.3675C29.069 10.0916 29.069 9.64537 28.793 9.36943Z" />
    </svg>
  );
};

export default ChevronDown;

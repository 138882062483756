import { NextApiRequest } from "next";

export const extractQueryParam = (x: string | string[] | undefined) =>
  x && (typeof x === "string" ? x : x[0]);

type Partial<T> = { [P in keyof T]?: T[P] };
export const getQueryInts = (req: NextApiRequest): Partial<{ [key: string]: number }> => {
  const convert = (q: string | string[] | undefined) => {
    if (typeof q === "string" && /^-?\d{1,9}$/.test(q)) return parseInt(q);
    return undefined;
  };
  return Object.assign({}, ...Object.keys(req.query).map((k) => ({ [k]: convert(req.query[k]) })));
};

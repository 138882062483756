import React from "react";

interface FilterPillProps {
  onClick: () => void;
  children: string;
  isActive: boolean;
}

const FilterPill: React.FC<FilterPillProps> = ({ onClick, children, isActive }) => {
  return (
    <button
      onClick={onClick}
      data-gtm-id="faq_choice"
      className={`text-label-xs tablet:py-3.5 tablet:px-6 tablet:text-label-sm flex cursor-pointer select-none flex-row items-center justify-between rounded-3xl px-4 py-3 font-sans font-bold ${
        isActive ? "bg-secondary-aubergine text-white" : "bg-grey-light text-primary-black"
      }`}
    >
      {children}
    </button>
  );
};

export default FilterPill;

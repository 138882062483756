import { ParsedUrlQuery } from "querystring";
import { useRouter } from "next/router";
import { dissoc, pathEq, pipe, not, ifElse, none, always, filter, any } from "ramda";
import { useEffect } from "react";
import { FaqSubject } from "@/services/prepr/queries/getFaqSubjects";
import { ServiceSubCategory } from "@/services/prepr/queries/getServiceSubcategories";
import { extractQueryParam } from "@/utilities/extractQueryParam";

type Subcategory = ServiceSubCategory | FaqSubject;

interface ReplaceInvalidQueryParamsProps {
  subCategories: Subcategory[];
  query: ParsedUrlQuery;
  needsReplacement?: boolean;
}

const hasCategory = pathEq(["category", 0, "name"]);

const isTruthy = (x: any) => !!x;

export const shouldRemoveCategory = ifElse(
  isTruthy,
  (x) => none(hasCategory(x)),
  () => () => false
);

const shouldRemoveSubCategory = (subjectName: string | undefined, topicName: string | undefined) =>
  subjectName
    ? pipe(
        filter<Subcategory>((subject) => subject?.name === subjectName),
        any(hasCategory(topicName)),
        not
      )
    : always(false);

export const replaceInvalidQueryParams = ({
  subCategories,
  query,
  needsReplacement,
}: ReplaceInvalidQueryParamsProps): null | ParsedUrlQuery => {
  if (!subCategories) return null;
  const categoryName = extractQueryParam(query.category);
  const subCategoryName = extractQueryParam(query.subCategory);
  const remove = (q: string) =>
    replaceInvalidQueryParams({
      subCategories,
      query: dissoc(q, query),
      needsReplacement: true,
    });
  return shouldRemoveSubCategory(subCategoryName, categoryName)(subCategories)
    ? remove("subCategory")
    : shouldRemoveCategory(categoryName)(subCategories)
    ? remove("category")
    : needsReplacement
    ? query
    : null;
};

export const useValidatedQueryParams = (subCategories: Subcategory[]): ParsedUrlQuery => {
  const router = useRouter();
  const replacedQueryParams = replaceInvalidQueryParams({
    subCategories,
    query: router.query,
  });

  useEffect(() => {
    if (replacedQueryParams !== null) {
      router.replace({ query: replacedQueryParams }, undefined, { scroll: false, shallow: true });
    }
  }, [replacedQueryParams]);

  return replacedQueryParams ?? router.query;
};
